<template>
    <div v-if="error" class="notify error">
        <renderer :input="error" />
    </div>
    <Spinner v-else-if="pageIsLoading" :visible="true" class="inset" />
    <div v-else-if="betslip" class="single-betslip">
        <div class="header">
            <div @click="onBack" class="back">
                <SvgIcon class="icon" icon-id="arrow_left" />
            </div>
            <div class="betslip-title">
                <p class="id" data-test-id="betSlipID">{{ $t('ui.singleBetslip.id') }} #{{ betslip.id }}</p>
                <p v-if="isVirtual" class="subtitle">{{ $t('ui.betslip.season', { name: betslip.virtualInfo.seasonName }) }}</p>
            </div>
            <div v-if="isShareButtonAvailable" class="share" @click="openBetSharingModal()">
                <Badge :text="$t('ui.common.new')" type="new" />
                <SvgIcon v-if="sharingLoading" class="icon spinner" icon-id="icon-spinner" />
                <SvgIcon v-else :class="['icon', { disabled: disabledSharingButton }]" icon-id="icon-share" />
            </div>
        </div>
        <div class="summary">
            <div class="betslip-summary-container">
                <div class="summary-line">
                    <div class="label">{{ $t('ui.myBets.odds') }}:</div>
                    <div class="value">{{ $numberFormat(betslip.payout.totalOdds, undefined, undefined, ' ') }}</div>
                </div>
                <template v-if="betslip.payout.oddsMultiplier">
                    <div class="summary-line">
                        <div class="label">{{ $t('ui.singleBetslip.oddsMultiplier') }}:</div>
                        <div class="value">x{{ betslip.payout.oddsMultiplier }}</div>
                    </div>
                    <div class="summary-line">
                        <div class="label bold">{{ $t('ui.singleBetslip.totalOdds') }}:</div>
                        <div class="value">{{ betslip.payout.totalOdds }}</div>
                    </div>
                </template>
                <div v-if="betslip.taxInfo.stakeTaxEnabled" class="summary-line">
                    <div class="label">{{ $t('ui.myBets.amount') }}:</div>
                    <Currency class="value" :amount="betslip.amount" :format="settings.currency" data-test-id="stakeTaxAmount" />
                </div>
                <div v-else class="summary-line">
                    <div class="label">{{ $t('ui.myBets.stake') }}:</div>
                    <Currency class="value" :amount="betslip.stake" :format="settings.currency" data-test-id="stakeAmount" />
                </div>
                <div v-if="betslip.taxInfo.stakeTaxEnabled" class="summary-line">
                    <div class="label">{{ $t('ui.singleBetslip.stakeAfterTax') }}:</div>
                    <Currency class="value" :amount="betslip.stake" :format="settings.currency" data-test-id="stakeAfterTaxAmount" />
                </div>
                <template
                    v-if="betslip.status === betStatus.WON || betslip.status === betStatus.CASHOUT || betslip.status === betStatus.PENDING"
                >
                    <div v-if="!isMultiplierSchema" class="summary-line">
                        <template v-if="betslip.status === betStatus.CASHOUT && betslip.cashoutInfo">
                            <div class="label bold">{{ $t('ui.singleBetslip.cashoutOfferGross') }}:</div>
                            <Currency
                                class="value"
                                :amount="betslip.cashoutInfo.result.gross"
                                :format="settings.currency"
                                data-test-id="possibleWinAmount"
                            />
                        </template>
                        <template v-else>
                            <div class="label">{{ $t('ui.betslip.potentialWinnings') }}:</div>
                            <Currency
                                class="value"
                                :amount="betslip.payout.netPossibleWin"
                                :format="settings.currency"
                                data-test-id="possibleWinAmount"
                            />
                        </template>
                    </div>
                    <div v-if="betslip.payout.bonus > 0 && isBonusSchema" class="summary-line" data-test-id="winBonusLine">
                        <div class="label">{{ $t(`ui.common.${countryCodeIs.KE ? 'plainWinBoost' : 'plainWinBonus'}`) }}:</div>
                        <Currency class="value" :amount="betslip.payout.bonus" :format="settings.currency" data-test-id="winBonusAmount" />
                    </div>
                    <template v-if="betslip.taxInfo.enabled && betslip.taxInfo.type">
                        <template v-if="isWhtTier && taxableWinnings">
                            <div class="summary-line">
                                <div class="label">{{ $t('ui.betslip.taxableWinnings') }}</div>
                                <Currency
                                    class="value"
                                    :amount="taxableWinnings"
                                    :format="settings.currency"
                                    data-test-id="taxableAmount"
                                />
                            </div>
                            <div class="summary-line">
                                <div class="label">
                                    {{ $t('ui.singleBetslip.Wht', { percentage: (taxTier && taxTier.rate * 100) || '' }) }}:
                                </div>
                                <Currency
                                    class="value"
                                    :amount="betslip.taxInfo.amount"
                                    :format="settings.currency"
                                    data-test-id="taxWhtTierAmount"
                                />
                            </div>
                        </template>
                        <div v-else-if="hasTaxInfo" class="summary-line" :class="{ strikethrough: strikethroughTax }">
                            <div v-if="!isWht" class="label">{{ $t('ui.betslip.tax') }}:</div>
                            <div v-else-if="isTaxOnWin" class="label">{{ $t('ui.singleBetslip.taxOnWin') }}:</div>
                            <div v-else class="label">
                                {{ $t('ui.singleBetslip.Wht', { percentage: betslip.taxInfo.percentage * 100 }) }}:
                            </div>
                            <Currency
                                v-if="betslip.status === betStatus.CASHOUT && betslip.cashoutInfo"
                                class="value"
                                :amount="betslip.cashoutInfo.result.tax"
                                :format="settings.currency"
                                data-test-id="possibleWinAmount"
                            >
                                -
                            </Currency>
                            <Currency
                                v-else
                                class="value"
                                :amount="betslip.taxInfo.amount"
                                :format="settings.currency"
                                data-test-id="taxAmount"
                            >
                                -
                            </Currency>
                        </div>
                        <div v-if="isWhtCompensated" class="summary-line">
                            <div class="label">{{ $t('ui.betslip.WhtBonus') }}</div>
                            <Currency
                                class="value"
                                :amount="betslip.taxInfo.amount"
                                :format="settings.currency"
                                data-test-id="taxCompensatedAmount"
                            />
                        </div>
                    </template>
                </template>
                <div class="summary-line" data-test-id="summaryLine">
                    <div class="label bold">{{ $t('ui.betslip.payout') }}:</div>
                    <div
                        v-if="betslip.status === betStatus.CANCELLED"
                        class="value"
                        data-test-class="betResult"
                        data-test-id="betResultCancelled"
                    >
                        {{ $t('ui.myBets.void').toUpperCase() }}
                    </div>

                    <Currency
                        v-else-if="betslip.status === betStatus.CASHOUT && betslip.cashoutInfo"
                        class="value winning-result"
                        :contrast="false"
                        :amount="betslip.cashoutInfo.result.net"
                        :format="settings.currency"
                        data-test-class="betResult"
                        data-test-id="betResultCashout"
                    >
                        {{ $t('ui.cashout.cashout').toUpperCase() }}
                    </Currency>
                    <Currency
                        v-else-if="betslip.status === betStatus.WON"
                        class="value winning-result"
                        :contrast="false"
                        :amount="betslip.payout.payout"
                        :format="settings.currency"
                        data-test-class="betResult"
                        data-test-id="betResultWon"
                    >
                        {{ $t('ui.myBets.won').toUpperCase() }}
                    </Currency>
                    <Currency
                        v-else-if="betslip.status === betStatus.PENDING"
                        class="value"
                        :amount="betslip.payout.payout"
                        :format="settings.currency"
                        data-test-class="betResult"
                        data-test-id="betResultPending"
                    />

                    <div v-else class="value" data-test-class="betResult" data-test-id="betResultLost">
                        {{ $t('ui.myBets.lost').toUpperCase() }}
                    </div>
                </div>
            </div>

            <CashoutWrapper v-if="isCashOutFeatureDisplayable" />

            <ListItem
                v-if="remainingOdds.length && !isVirtual"
                :class="['button-primary', 're-bet', isRebetDisabled && 'disabled']"
                :actionIconEnabled="!$mq.isSmallest"
                :simple="$mq.isSmallest"
                noWrapActionContent
                data-test-id="copyToMySlip"
                @[reBetEventName].native="reBet(selectionsIds)"
            >
                <template slot="content">
                    {{
                        remainingOdds.length === betslip.selections.length ? $t('ui.betslip.reUseSelections') : $t('ui.singleBetslip.reBet')
                    }}
                </template>
                <template slot="action-content">
                    {{ remainingOdds.length }}
                </template>
            </ListItem>
        </div>
        <div v-if="showInPlayInfo" class="inplay-info">
            <Countdown
                v-if="canDisplayCountDown"
                :start="anyInPlayGame.inPlayDetails.startCounter"
                theme="live-event"
                strokeWidth="6"
                :refreshRate="liveScoreRefreshRate"
                @update="getInPlayList"
            >
            </Countdown>
            <div class="icon-live" v-else>
                <SvgIcon iconId="icon-live" class="icon icon-size-medium" verticalAlign="unset" />
            </div>
            <renderer class="inplay-info-text" :input="$t('ui.singleBetslip.inPlay.info')" @clickEvent="clickEvent" />
        </div>
        <SingleBetslipOdd
            :odd="odd"
            :isVirtual="isVirtual"
            :isLocked="isLocked(odd.selection.id)"
            v-for="odd in betslipOdds"
            :key="odd.selection.id"
        />
        <div v-if="allBetsResolved" class="notify warning">
            {{ $t('ui.singleBetslip.pendingResultConfirmation') }}
        </div>
        <div class="legend">
            <div class="lightgray placed-date">
                <div v-if="betslip.placed">
                    {{ $t('ui.singleBetslip.betPlacedOn') }}
                    <span class="nowrap">{{ getDate(betslip.placed) + $t('ui.myBets.at') + getTime(betslip.placed) }}</span>
                </div>
                <div class="summary-legend">
                    <badge type="pending" mode="circle" />
                    <span>{{ $t('ui.myBets.pending') }}</span>
                    <badge type="win" mode="circle" />
                    <span>{{ $t('ui.myBets.won') }}</span>
                    <badge type="lose" mode="circle" />
                    <span>{{ $t('ui.myBets.lost') }}</span>
                    <badge type="cancelled" mode="circle" iconName="icon-close" />
                    <span>{{ $t('ui.myBets.void') }}</span>
                </div>
            </div>
        </div>
        <MyBetsRegulatoryMessage :betType="isVirtual ? 'virtualBets' : 'realBets'" />
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { deviceType, helper, getter as coreGetter } from '@agi.packages/core';
import { Currency, Spinner, ListItem } from '@agi.packages/core/components';
import { betslip, myBetsType, sport, WHT_TYPES, GROSS_TAX_TYPES, whtTax, bonusType, betStatus } from '@agi.packages/sport';
import { getter as platformGetter } from '@agi.packages/platform';
import { getter as generalGetter } from '@/store/store';

import PageMixin from '@/components/Pages/Page.mixin';
import BackButtonMixin from '@/components/Pages/BackButton.mixin';
import SEOMixin from '@/components/Pages/SEO.mixin';
import Badge from '@/components/Badge';
import Countdown from '@/components/Countdown';
import { SingleBetslipOdd, MyBetsRegulatoryMessage } from '@agi.packages/sport/components';
import { routeName } from '@/router/const-name';
import { DISABLED_BUTTON_TIMEOUT } from '@/modules/sport/const/betslip-const';
import CashoutWrapper from '@/modules/sport/components/Fragments/Cashout/CashoutWrapper.vue';

export default {
    name: 'SingleBetslipView',
    mixins: [PageMixin, BackButtonMixin, SEOMixin],
    components: {
        CashoutWrapper,
        MyBetsRegulatoryMessage,
        Badge,
        Currency,
        Spinner,
        ListItem,
        Countdown,
        SingleBetslipOdd,
    },
    data: () => ({
        afterBetPlaced: false,
        coolOffReBet: false,
        betStatus,
        sharingLoading: false,
        disabledSharingButton: false,
    }),
    created() {
        if (!this.isPresto) {
            this.$store.commit(sport.mutation.UPDATE_CASHOUT_POLLING, { pollingEnabled: false });
        }
    },
    computed: {
        ...mapState({
            betslip: (state) => state.sport.myBets.betslip,
            error: (state) => state.sport.myBets.error,
            requestOfferError: (state) => state.sport.myBets.offerError,
            isOfferConfirmed: (state) => state.sport.myBets.offerStatus.confirmed,
            pollingCounter: (state) => state.sport.myBets.offerStatus.pollingCounter,
            illegalSelections: (state) => state.sport.myBets.offerError.illegalSelections,
        }),
        ...mapGetters({
            dateOptions: platformGetter.GET_DATE_OPTIONS, // Implement global date time BP-17850
            isLoading: coreGetter.IS_LOADING,
            settings: platformGetter.GET_SETTINGS,
            isVirtualBetslip: betslip.getter.IS_VIRTUAL_BETSLIP,
            lockedMarkets: sport.getter.GET_LOCKED_MARKETS,
            getMenuItemByBetslip: generalGetter.GET_MENU_ITEM_BY_BETSLIP,
            brandPreferences: platformGetter.GET_BRAND_PREFERENCE,
            isCashOutAvailable: platformGetter.IS_CASHOUT_AVAILABLE,
            countryCodeIs: platformGetter.COUNTRY_CODE_IS,
            offerPolling: sport.getter.GET_CASHOUT_OFFER_POLLING,
        }),
        hasTaxInfo() {
            return (
                this.betslip.taxInfo.amount ||
                (this.betslip.cashoutInfo && this.betslip.cashoutInfo.result && this.betslip.cashoutInfo.result.tax)
            );
        },
        isMultiplierSchema() {
            return this.betslip.payout.bonusType === bonusType.ODDS_MULTIPLIER;
        },
        isBonusSchema() {
            return this.betslip.payout.bonusType === bonusType.BONUS;
        },
        liveScoreRefreshRate() {
            const { myBetsLiveScoreRefreshRateSeconds } = this.brandPreferences;
            return myBetsLiveScoreRefreshRateSeconds;
        },
        betslipOdds() {
            if (this.isVirtual) return this.betslip.selections;

            const copyOdds = this.betslip?.selections ? this.betslip.selections.slice() : [];
            const betslipOddsSorter = (a, b) => {
                const aDisplayMinute = this.$getObjectField(a, 'inPlayDetails.display.minute', null);
                const bDisplayMinute = this.$getObjectField(b, 'inPlayDetails.display.minute', null);
                return (
                    bDisplayMinute - aDisplayMinute ||
                    Date.parse(a.selection.starts) - Date.parse(b.selection.starts) ||
                    a.selection.event.name.toLowerCase().localeCompare(b.selection.event.name.toLowerCase())
                );
            };
            return copyOdds.sort(betslipOddsSorter);
        },
        showInPlayInfo() {
            return (
                !this.isPresto &&
                this.betslipOdds.some(
                    (selection) => selection.inPlayDetails && this.$getObjectField(selection, 'inPlayDetails.startCounter', false)
                )
            );
        },
        canDisplayCountDown() {
            return this.betslipOdds.some(
                (selection) =>
                    selection.inPlayDetails &&
                    (selection.inPlayDetails.display || (selection.inPlayDetails.matchScore && selection.inPlayDetails.matchState))
            );
        },
        anyInPlayGame() {
            return this.betslipOdds.find(
                (selection) => selection.inPlayDetails && this.$getObjectField(selection, 'inPlayDetails.startCounter', false)
            );
        },
        isCashOutFeatureDisplayable() {
            return this.isCashOutAvailable && (this.isBetslipCashOutAble || this.requestOfferError.message);
        },
        isBetslipCashOutAble() {
            return (
                this.betslip?.cashoutInfo?.cashoutable &&
                this.betslip.status === betStatus.PENDING &&
                !this.betslip.selections.every((selection) => selection.result || selection.status !== betStatus.PENDING)
            );
        },
        isVirtual() {
            return this.betslip.betslipType.toLowerCase() === myBetsType.VIRTUAL;
        },
        isTaxOnWin() {
            return this.settings.betting.real.whtDisplayType === 'TAX_ON_WIN';
        },
        formIsLoading() {
            return this.isLoading(betslip.action.COPY_BETS);
        },
        pageIsLoading() {
            return this.isLoading(sport.action.GET_SINGLE_BETSLIP);
        },
        isWht() {
            return WHT_TYPES.includes(this.betslip.taxInfo.type);
        },
        isWhtTier() {
            return this.betslip.taxInfo.type === whtTax.WHT_NET_TIERED;
        },
        taxableAmount() {
            const { netPossibleWin, bonus } = this.betslip.payout;

            if (this.betslip?.cashoutInfo?.result) {
                const { stake } = this.betslip;
                const grossAmount = this.betslip.cashoutInfo.result.gross;
                return this.$rounded(grossAmount) - this.$rounded(stake);
            }

            return this.$rounded(netPossibleWin) + this.$rounded(bonus);
        },
        taxTier() {
            const taxMetaData = this.settings?.taxBrand?.real?.win;
            const taxVirtualMetaData = this.settings?.taxBrand?.virtual?.win;
            const taxRanges = (this.isVirtualBetslip ? taxVirtualMetaData?.rates : taxMetaData?.rates) || [];
            return taxRanges.find((range) => this.taxableAmount >= range.from && this.taxableAmount <= (range.to || Infinity));
        },
        taxableWinnings() {
            const { from } = this.taxTier || {};
            return from && this.taxableAmount - from;
        },
        /**
         * isWhtCompensated method
         * @deprecated
         * in new component-data API
         * @returns {(function(): *)|*|boolean}
         */
        isWhtCompensated() {
            return this.betslip.taxInfo?.compensationType && this.betslip.taxInfo.compensationType !== 'NONE';
        },
        strikethroughTax() {
            return GROSS_TAX_TYPES.includes(this.betslip.taxInfo.type) && this.betslip.taxInfo.whtOffset;
        },
        remainingOdds() {
            return this.betslip.selections.filter(({ status }) => status === betStatus.PENDING);
        },
        selectionsIds() {
            return this.remainingOdds.map((selection) => selection.selection.id);
        },
        allBetsResolved() {
            if (this.betslip.status !== betStatus.PENDING) {
                return false;
            }
            if (!this.remainingOdds.length) {
                this.$gtm.query({ event: 'bet_not_settled' });
            }
            return !this.remainingOdds.length;
        },
        allMatchesFinished() {
            return this.betslip.selections.every(
                (selection) =>
                    selection.selection.resultText ||
                    (new Date(selection.selection.starts).getTime() < new Date().getTime() && !selection.selection.inPlay) ||
                    selection.status !== betStatus.PENDING
            );
        },
        isRebetDisabled() {
            return this.formIsLoading || this.allMatchesFinished || this.coolOffReBet;
        },
        reBetEventName() {
            return this.isRebetDisabled ? null : 'click';
        },
        isShareButtonAvailable() {
            return !this.isVirtual && !this.formIsLoading && this.remainingOdds.length;
        },
    },
    watch: {
        $route: {
            immediate: true,
            handler() {
                // overall it looks odd, refactor? BP-16141
                this.afterBetPlaced = this.$store.state.sport.ui.afterBetPlaced;
                this.$store.commit('sport/setAfterBetPlaced', false); // add mutation BP-16141
                this.$store.commit('sport/setSingleBetslip', null); // add mutation BP-16141
                const { id } = this.$route.params;
                if (this.$route.query?.force) {
                    this.getDetailBetslip(id);
                } else {
                    this.getSingleBetslip(id);
                }
            },
        },
    },
    methods: {
        $numberFormat: helper.numberFormat,
        $interpolate: helper.interpolate,
        $formatDateTime: helper.formatDateTime,
        $rounded: helper.rounded,
        $getObjectField: helper.getObjectField,
        ...mapActions({
            copyBets: betslip.action.COPY_BETS,
            getDetailBetslip: sport.action.GET_DETAIL_BETSLIP,
            getSingleBetslip: sport.action.GET_SINGLE_BETSLIP,
            getInPlayList: sport.action.GET_IN_PLAY_LIST,
        }),
        ...mapMutations({
            clearAutoCashOutOffer: sport.mutation.CLEAR_AUTO_CASHOUT_OFFER,
            clearAutoCashOutOfferError: sport.mutation.CLEAR_AUTO_CASHOUT_OFFER_ERROR,
            setCashOutOfferError: sport.mutation.SET_CASHOUT_OFFER_ERROR,
        }),
        reBet(selectionIds) {
            if (!this.isRebetDisabled) {
                this.$gtm.query({ event: 're-bet' });
                this.coolOffReBet = !deviceType.isPresto();
                this.copyBets(selectionIds).then(() => {
                    this.coolOffReBet && setTimeout(() => (this.coolOffReBet = false), DISABLED_BUTTON_TIMEOUT);
                });
            }
        },
        clickEvent() {
            this.$gtm.query({
                event: 'inplay_read_more_click',
            });
        },
        isLocked(oddSelectionId) {
            return (
                !!this.offerPolling.pollingEnabled && !!this.lockedMarkets.length && this.lockedMarkets.some((id) => id === oddSelectionId)
            );
        },
        onBack() {
            if (this.isOfferConfirmed) {
                this.$router.push({ name: routeName.MY_BETS, params: { section: 'settled' } });
            } else if (this.afterBetPlaced) {
                const section = this.isVirtual ? 'virtuals' : 'open';
                this.$router.push({ name: routeName.MY_BETS, params: { section } });
                this.afterBetPlaced = false;
            } else {
                const { betslipType, status } = this.betslip;
                const section = this.getMenuItemByBetslip({ betslipType, status })?.name;
                this.$router.push({ name: routeName.MY_BETS, params: { section } });
            }
        },
        getTime(rawDate) {
            return this.$formatDateTime(rawDate, { needDate: false, isUtc: true, ...this.dateOptions });
        },
        getDate(rawDate) {
            return this.$formatDateTime(rawDate, { needTime: false, isUtc: true, ...this.dateOptions });
        },
        openBetSharingModal() {
            if (this.disabledSharingButton) return;

            this.$gtm.query({ event: 'single_bet_slip_booking_code_create' });
            this.sharingLoading = true;
            const selectionIds = this.betslipOdds.map((item) => item.selection.id);
            this.$store.dispatch(betslip.action.LOAD_SHARE_LINKS, selectionIds).finally(() => {
                this.$modal.show('bet-sharing-modal');
                this.sharingLoading = false;
            });
        },
    },
    beforeDestroy() {
        this.clearAutoCashOutOffer();
        this.clearAutoCashOutOfferError();
        this.setCashOutOfferError();
        this.$store.commit(sport.mutation.UPDATE_CASHOUT_POLLING, {
            pollingEnabled: false,
            requestLimit: 0,
        });
    },
};
</script>

<style lang="scss" scoped>
@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.inplay-info {
    font-size: 12px;
    padding: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e6e7e2;
    .icon-info {
        fill: $event-live-status-color;
        width: 16px;
        height: 16px;
    }

    .icon-live {
        width: 16px;
        height: 16px;

        svg {
            fill: $event-live-status-color;
        }
    }

    &-text {
        margin-left: 8px;
    }
}

.header {
    align-items: center;
    background-color: $white-bg;
    border-bottom: $single-betslip-header-border;
    display: flex;
    padding: 12px;

    .back,
    .share {
        color: $main-text;
        cursor: pointer;

        .icon {
            height: 16px;
            width: 16px;
            max-height: 16px;
            max-width: 16px;
        }
    }

    .share {
        display: flex;
        align-items: center;

        & .spinner {
            animation: spin 1s infinite linear;
            display: block;
        }

        & .disabled {
            fill: $disabled-text;
        }
    }

    .betslip-title {
        display: inline-block;
        text-align: center;
        width: 100%;

        .id {
            font-weight: 700;
        }

        .subtitle {
            @extend %caption-font-400;
            color: $disabled-text;
            text-transform: uppercase;
        }
    }
}

.re-bet {
    background: $single-betslip-re-bet-background;

    &.disabled {
        background: $disabled-bg;
    }
}

.betslip-summary-container {
    border: 1px solid $dark-grey;
    background-color: white;
    padding: 12px;
}

.summary {
    @extend %body-normal-font-400;
    background: $single-betslip-summary-background-color;
    border-bottom: $single-betslip-border;
    padding: 8px;

    .summary-line {
        display: flex;
        justify-content: space-between;
        padding-bottom: 8px;

        .label {
            color: $main-text;
            display: inline-block;
            text-align: left;

            &.bold {
                font-weight: bold;
            }
        }

        .value {
            display: inline-block;
            font-weight: bold;
            text-align: right;
        }

        .winning-result {
            color: $green-success;
        }

        .currency {
            font-size: 10px;

            &::v-deep .amount {
                font-size: 14px;
            }
        }

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}

.notify {
    margin: 8px;
    text-align: center;
}

.legend {
    padding: 0 8px 8px;
    text-align: center;

    .copy {
        margin-bottom: 8px;
    }

    .placed-date {
        @extend %small-details-font-400;
        margin-top: 8px;
    }

    .summary-legend {
        margin-top: 6px;

        & .badge {
            margin: 0 4px 0 12px;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .nowrap {
        white-space: nowrap;
    }
}
</style>
